import { Button } from "reactstrap";
import SocialIcon from "../../components/social-icon";


const Footer = () => {
    return (
        <div className="section bg-dark">
            <div className="container">
                <div className="footer-info-box text-center">
                    <h4 className="title">Let’s work together</h4>
                   
                    <div>
                        <img src="https://joelwembo.com/images/me.png" width="20%" height="19%" style={{ borderradius: 50}}  alt="joel otepa wembo joelwembo.com" />
                    </div>
                                
                   
                    <h2 className="mail">
                        <a href="https://discord.com/users/880427220617797703">Discord</a>
                    </h2>
                    <span className="number">
                                    <a href="https://www.linkedin.com/in/joelotepawembo/">
                                        <SocialIcon
                                            path="https://www.linkedin.com/in/joelotepawembo/"
                                            icon="fab fa-linkedin"
                                    
                                        />  joelotepawembo
                                    </a>
                    </span>

                    <div>

                            <Button outline color="warning" style={{marginLeft:1 , marginTop:40}}> <a href="https://calendly.com/joelotepawembo/devops-cloud-engineering-event">Schedule Appointment</a>   </Button>{' '}
                            </div>

                </div>

                <div className="footer-copyright-social">
                    <div className="footer-copyright">
                        <p>
                            &copy; 2023 <span> Joel Wembo</span> Made with{" "}
                            <i className="icofont-heart" aria-hidden="true"></i>{" "}
                            by{" "}
                            <a
                                href="https://joelwembo.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                JOEL O. WEMBO
                            </a>
                        </p>
                    </div>
                    <div className="footer-social">
                        <ul className="social">
                           
                        <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="google"
                                    href="https://play.google.com/store/apps/dev?id=7748277474699889776"
                                >
                                    Google Developer
                                </a>
                            </li>
                           
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="twitter"
                                    href="https://twitter.com/joelwembo1"
                                >
                                    Twitter
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="facebook"
                                    href="https://facebook.com/joelotepawembo"
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="behance"
                                    href="https://www.behance.net/joelwembo"
                                >
                                    Behance
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="dribbble"
                                    href="https://dribbble.com/joelwembo"
                                >
                                    Dribbble
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="github"
                                    href="https://github.com/joelwembo"
                                >
                                    Github
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
