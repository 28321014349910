/* eslint-disable react/jsx-no-target-blank */
import ContactForm from "../../components/contact-form";
import SocialIcon from "../../components/social-icon";

import { Button } from "reactstrap";


const ContactContainer = () => {
    return (
        <div className="section contact-section bg-dark">
            <div
                className="contact-bg"
                style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/blog/main-2.jpg)`,
                }}
            ></div>

            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-lg-7">
                        <div className="contact-section-wrapper">
                            <div className="contact-info" data-aos="fade-up">
                                
                                
                                <div>
                                    <img src="https://joelwembo.com/images/me.png" width="60%" height="59%" style={{ borderradius: 50}}  alt="joel otepa wembo joelwembo.com" />
                                </div>
                                
                                <span className="mail">
                                   <a href="https://discord.com/users/880427220617797703">Discord</a>
                                </span>
                                <span className="number">
                                    <a href="https://www.linkedin.com/in/joelotepawembo/">
                                        <SocialIcon
                                            path="https://www.linkedin.com/in/joelotepawembo/"
                                            icon="fab fa-linkedin"
                                    
                                        />  linkedin : joelotepawembo
                                    </a>
                                </span>

                            
                            </div>

                            <div
                                className="contact-form"
                                data-aos="fade-up"
                                data-aos-duration="600"
                            >
                                <ContactForm />
                            </div>

                            <div>

                            <Button outline color="primary" style={{marginLeft:200}}> <a href="https://calendly.com/joelotepawembo/devops-cloud-engineering-event">Make an Appointment Instead ?</a>   </Button>{' '}
                            </div>

                            <div
                                className="contact-social"
                                data-aos="fade-up"
                                data-aos-duration="900"
                            >
                                <ul>
                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="dribbble"
                                            href="https://dribbble.com/joelwembo"
                                        >
                                            Dribbble
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="behance"
                                            href="https://www.behance.net/joelwembo"
                                        >
                                            Behance
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="twitter"
                                            href="https://twitter.com/joelwembo1"
                                        >
                                            Twitter
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            className="facebook"
                                            href="https://facebook.com/joelotepawembo"
                                        >
                                            Facebook
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactContainer;
