const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/blog/main-6.jpg)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Joel O. Wembo</h3>
                                <p className="about-text">
                                Critical thinking Solutions Architect, DevOps/Cloud Engineer with extensive understanding of high availability architecture and concepts. Possess Knowledge in Cloud, DevOps and adept at utilizing open-source resources for execution of an enterprise application. 
                                </p>
                                <p>
                                I Specialize in building applications for financial services, IT and security services using AWS, Azure, GCP, Kubernetes, Jenkins, Github Actions, Bash Tools, Ansible, Terraform, Chef, Docker, Kafka, Python, Django, JavaScript and React.
                                </p>
                                
                                <img
                                    className="signechar"
                                    src="./images/signechar.png"
                                    alt="joel otepa wembo"
                                />
                                <h6 className="name">Joel Otepa Wembo</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
